<template>
    <template v-if="type === 'submit'">
        <a-button :type="color" html-type="submit">{{ text }}</a-button>
    </template>

    <template v-if="type === 'link'">
        <router-link :to="url" class="ant-btn" :class="'ant-btn-' + color">{{ text }}</router-link>
    </template>

    <template v-if="type === 'save' || type === 'saveAndClose'">
        <a-button @click="handleSaveBtnClick" :type="color" html-type="submit">
            {{ text }}
        </a-button>
    </template>

    <template v-if="(type === 'create' || type === 'edit')">
        <div class="action-box custom-button">
            <router-link :to="url" class="ant-btn" :class="'ant-btn-' + color">{{ text }}</router-link>
        </div>
    </template>

    <template v-if="type === 'delete'">
        <a-popconfirm v-model:visible="state.DeleteBtn.visible" title="Are you sure">
            <template #icon></template>
            <template #cancelButton>
                <button @click="onDeleteCancel" class="ant-btn ant-btn-orange">No</button>
            </template>
            <template #okButton>
                <button @click="onDeleteOk" class="ant-btn ant-btn-blue">Yes</button>
            </template>
            <a-button type="primary" danger ghost>{{ text }}</a-button>
        </a-popconfirm>
    </template>

    <template v-if="type === 'sync'">
        <a-button type="blue" @click="HandelSync">{{ text }}</a-button>
    </template>
</template>

<script setup>
import { reactive} from "vue";
import {ErrorHandler, errorMessage, successMessage, ToggleLoader} from "@/helpers";
import http from "@/http";

const emit = defineEmits(['delete', 'synced', 'redirect'])
const props = defineProps({
    url: String,
    type: {
        type: String,
        required: true
    },
    text: {
        type: String,
        required: true
    },
    color: {
        type: String,
        default: 'orange'
    }
})

const state = reactive({
    DeleteBtn: {
        visible: false
    }
})

const handleSaveBtnClick = () => {
    if (props.type === 'save') return emit('redirect', false)
    return emit('redirect', true)
}

const onDeleteOk = () => {
    emit('delete', true)
}

const onDeleteCancel = () => {
    state.DeleteBtn.visible = false
}

const HandelSync = async () => {
    ToggleLoader(true, true)
    try {
        const res = await http.get(props.url)

        const {data, status} = res
        if (status === 200) {
            emit('synced', true)
            return successMessage(data.message)
        }
    } catch (error) {
        ToggleLoader(false, false)
        return errorMessage(ErrorHandler(error))
    }
}
</script>

