import store from "@/store/index"
import axios from "axios";

let apiUrl
let NODE_ENV = process.env.VUE_APP_NODE_ENV

if (NODE_ENV === "production" || NODE_ENV === "staging" || NODE_ENV === "local" || NODE_ENV === "development") {
    apiUrl = process.env.VUE_APP_API
    if (store.state.User && store.state?.User?.token) {
        apiUrl += '/protected'
    }
}

const instance = axios.create({
    baseURL: apiUrl,
    timeout: 1000000,
});

if (store.state.User && store.state?.User?.token) {
    instance.defaults.headers.common['Authorization'] = store.state?.User?.token
    instance.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (401 === error.response.status) {
            return store.dispatch('LogoutUser')
        } else {
            return Promise.reject(error);
        }
    });
}

export default instance
