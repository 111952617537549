<template>
    <div class="main-box">
        <a-form
            :model="formState"
            name="photoset_create"
            autocomplete="off"
            :validate-messages="validateMessages"
            @finish="Submit"
        >
            <div class="field-row half">
                <a-form-item
                    label="Title"
                    name="title"
                    :rules="[{ required: true}]"
                >
                    <a-input v-model:value="formState.title" placeholder="Title"/>
                </a-form-item>
                <a-form-item
                    label="Type"
                    name="type"
                    :rules="[{ required: true}]"
                >
                    <a-select
                        v-model:value="formState.type"
                        placeholder="Please select"
                        :options="SliderType"
                        :filter-option="filterDropDownOptions"
                    />
                </a-form-item>
            </div>

            <div class="field-row">
                <button-component type="submit" color="blue" text="Create"/>
            </div>
        </a-form>
    </div>
</template>

<script setup>
import {reactive} from "vue";
import validateMessages from "@/helpers/validateMessages";
import {ErrorHandler, errorMessage, successMessage, ToggleLoader} from "@/helpers";
import http from "@/http";
import router from "@/router";
import {filterDropDownOptions} from "@/helpers/filterDropDownOptions";

const formState = reactive({
    title: null,
    type: null
})
const SliderType = [
    {
        value: 'EDITOR_CHOICE',
        label: "Editor Choice"
    },
    {
        value: 'HIGHLIGHTS',
        label: "Highlights"
    },
]
const Submit = async (values) => {
    ToggleLoader(true, true)
    try {
        const res = await http.put('/slider', {...values, is_active: 0})
        ToggleLoader(false, false)
        await router.replace(`/slider/${res.data.data.id}`)
        return successMessage('Collection created')
    } catch (error) {
        ToggleLoader(false, false)
        return errorMessage(ErrorHandler(error))
    }
}

</script>
