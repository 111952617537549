<template>
    <header>
        <div class="header-wrapper">
            <div class="left">
                <div class="logo">
                    <Logo :with-label="true"/>
                </div>
                <NavigationComponent/>
            </div>
            <div class="right">
                <div class="user-box">
                    <div class="user-name">
                        {{ UserState && UserState.username ? UserState.username : 'Default' }}
                    </div>
                </div>

                <div class="logout-box">
                    <button @click="Submit">
                        Log Out
                    </button>
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
import NavigationComponent from "@/components/partials/NavigationComponent.vue";
import {Logout} from "@/http/requests";
import {computed} from "vue";
import {useStore} from "vuex";
import Logo from '@/assets/icons/PhotoLurLogo.vue'

const store = useStore()
const UserState = computed(() => {
    return store.state.User
})

const Submit = () => {
    Logout('/users/logout')
}

</script>
