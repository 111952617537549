<template>
    <a-menu v-model:selectedKeys="CurrentItem" mode="horizontal">
        <a-menu-item key="photographers:list">
            <router-link to="/photographers">Photographers</router-link>
        </a-menu-item>
        <a-menu-item key="collection:list">
            <router-link to="/collection">Collection</router-link>
        </a-menu-item>
        <a-menu-item key="file:manger">
            <router-link to="/file-manager">File Manager</router-link>
        </a-menu-item>
        <a-menu-item key="config">
            <router-link to="/config">Config</router-link>
        </a-menu-item>
        <a-menu-item key="slider:list">
            <router-link to="/slider">Slider</router-link>
        </a-menu-item>
    </a-menu>
</template>

<script setup>
import {useRouter} from 'vue-router';
import {computed} from "vue";

const CurrentItem = computed(() => {
    return [useRouter().currentRoute.value.meta.menuKey]
})
</script>
