<template>
    <div class="tool-box">
        <div class="filter-box">
            <template v-for="(item , index) in SetUp" :key="index">
                <template v-if="item.type === 'search'">
                    <div class="field-row">
                        <a-input-search
                            v-model:value="state.searchValue"
                            @search="onSearch"
                            :placeholder="item.placeholder"
                            @change="event => {
                            const {value} = event.target

                            if(value === '') {
                                onSearch(value)
                            }
                        }"
                        />
                    </div>
                </template>
                <template v-if="item.type === 'date'">
                    <div class="field-row">
                        <a-date-picker
                            v-model:value="state.dateValue"
                            :allowClear="false"
                            :disabled-date="disabledDate"
                            @change="dateChange"
                        />
                    </div>
                </template>
            </template>
        </div>
        <div class="action-box">
            <button-component
                text="create"
                type="create"
                color="blue"
                v-if="createUrl" :url="createUrl"
            />
            <button-component
                text="edit"
                type="edit"
                color="orange"
                v-if="editUrl"
                :url="editUrl"
            />
        </div>
    </div>
</template>

<script setup>
import dayjs from 'dayjs';
import {reactive} from "vue";

defineProps({
    SetUp: Array,
    createUrl: String,
    editUrl: String,
    searchValue: Function,
})

const emit = defineEmits(['onSearch', 'onDate'])
const state = reactive({
    dateValue: dayjs(),
    searchValue: '',
})

const disabledDate = current => {
    return current && current > dayjs().endOf('day');
};

const onSearch = (data) => {
    emit('onSearch', data)
}

const dateChange = (data) => {
    emit('onDate', data)
}

</script>
