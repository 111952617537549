<template>
    <template v-if="ENV !== 'production'">
        <div class="env-label">{{ ENV }}</div>
    </template>
    <LoaderComponent v-if="state.loader"/>
    <LoaderComponent v-if="LoaderState.show" :transparent="LoaderState.transparent"/>
    <HeaderComponent v-if="UserState"/>
    <router-view v-if="state.mount"/>
</template>

<script setup>
import './scss/style.scss'
import HeaderComponent from "@/components/layout/HeaderComponent";
import LoaderComponent from "@/components/partials/LoaderComponent";
import {computed, onMounted, reactive} from "vue";
import {useStore} from 'vuex'
import moment from "moment-timezone";
import http from "@/http";
import {ErrorHandler, errorMessage} from "@/helpers";
const ENV = process.env.VUE_APP_NODE_ENV

const state = reactive({
    loader: false,
    mount: false
})
const store = useStore()

const LoaderState = computed(() => {
    return {
        show: store.state.Loader.show,
        transparent: store.state.Loader.transparent
    }
})

const UserState = computed(() => {
    return store.state.User
})
const HasCdnUrl = computed(() => {
    return store.state.CDN_URL
})
onMounted(async () => {
    moment.tz.setDefault("Asia/Yerevan");
    if (!HasCdnUrl?.value && UserState.value) {
        state.loader = true
        const {data, status} = await getConfigs()
        if (status === 200 && data) {
            await store.dispatch('SetCdnUrl', data.CDN_URL)
            state.loader = false

        }
    }
    state.mount = true
})
const getConfigs = async () => {
    try {
        const res = await http.get('/configuration')
        return {
            data: res.data.data,
            status: res.status
        }
    } catch (error) {
        return errorMessage(ErrorHandler(error))
    }
}
</script>
