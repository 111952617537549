<template>
    <div class="main-box file-manager">
        <ToolBox
            v-on:onSearch="handleSearchValue"
            :SetUp="toolBoxSetUp"
        />

        <template v-if="ManagerType === 'select' && ChosePhotoList.photos.length">
            <a-button class="select-btn" type="orange" @click="PrepareForAssign">Select</a-button>
        </template>

        <div class="photo-list">
            <template v-for="(file,photoIndex) in state.list" :key="photoIndex">
                <div class="photo-item" @click="handleItemClick(file.id,photoIndex)"
                     :class="setSelectedClassName(file.id,photoIndex)"
                >
                    <PictureComponent
                        :url="file.thumbnail"
                        :width="file.width"
                        :height="file.height"
                        :alt="file.alt"
                        :title="file.title"
                    />
                    <div class="file-info">
                        <span class="file-code">Code: {{ file.image_code }}</span>
                        <span class="file-status">
                            Status: <a-typography-text :type="file.is_active ? 'success' : 'danger'">
                            {{
                                file.is_active ? 'Active' : 'Passive'
                            }}
                        </a-typography-text>
                        </span>
                    </div>
                </div>
            </template>
        </div>

        <a-pagination
            v-model:current="state.currentPage"
            :defaultPageSize="state.perPage"
            simple
            :total="state.itemCount"
            @change="handlePageChange"
        />

        <FileEditComponent :PhotographersList="state.PhotographersList"/>
    </div>
</template>

<script setup>
import PictureComponent from "@/components/partials/PictureComponent.vue";
import ToolBox from "@/components/partials/ToolBox.vue";
import {ErrorHandler, errorMessage, ToggleLoader} from "@/helpers";
import http from "@/http";
import {computed, onMounted, reactive, watch} from "vue";
import FileEditComponent from "@/components/partials/FileManager/FileEditComponent.vue";
import store from "@/store";
import {getDropDownOptions} from "@/http/requests";

const emit = defineEmits(['SelectedPhotoList'])
const props = defineProps({
    ManagerType: {
        type: String,
        default: 'editable'
    },
    SelectedPhotos: Array
})

const toolBoxSetUp = [
    {
        type: 'search',
        paramKey: '%s',
        name: 'filter',
        placeholder: 'Search by Text',
    }
]

const state = reactive({
    currentPage: 1,
    itemCount: null,
    perPage: 50,
    search: '',
    list: [],
    PhotographersList: []
})

const setSelectedClassName = (fileId, photoIndex) => {
    let className = ''
    let fileObject = ChosePhotoList.photos.filter(item => item.id === fileId)
    if (fileObject.length) {
        className = 'photo-item-selected'
        state.list[photoIndex].selected = true
    }
    return className
}

const ChosePhotoList = reactive({
    photos: props.SelectedPhotos?.length ? props.SelectedPhotos : []
})

const UpdatedFileData = computed(() => {
    return store.state.FileManager.UpdatedFileData
})

watch(UpdatedFileData, (data) => {
    if (data && data.id) {
        const index = state.list.findIndex(item => {
            return item.id === data.id;
        });
        delete data.url
        state.list[index] = {...state.list[index], ...data}
        store.commit('FileManager/setUpdatedFileData', {})
    }
})

onMounted(() => {
    getList(state.perPage).then(payload => {
        let {data, meta, status} = payload
        if (status === 200 && data) {
            state.list = data
            state.itemCount = meta.itemCount
            ToggleLoader(false, false)
        }
    })
    if (props.ManagerType === 'editable') {
        getDropDownOptions('/photographers/dictionary/data').then(res => {
            const {data, status} = res
            if (status === 200 && data) {
                state.PhotographersList = data
            }
        })
    }
})
const handleItemClick = (FileID, FileIndex) => {
    if (props.ManagerType === 'editable') {
        store.commit('FileManager/setFileId', FileID)
        store.commit('FileManager/changePopUpVisibility', true)
        return store.commit('FileManager/setPopUpData', state.list[FileIndex])
    }

    const photoItem = state.list[FileIndex]
    if (state.list[FileIndex].selected) {
        state.list[FileIndex].selected = false
        ChosePhotoList.photos = ChosePhotoList.photos.filter(item => item.id !== FileID)
    } else {
        state.list[FileIndex].selected = true
        ChosePhotoList.photos.push({
            id: FileID,
            image_code: photoItem.image_code,
            thumbnail: photoItem.thumbnail,
            loader: false
        })
    }
}
const PrepareForAssign = () => {
    emit('SelectedPhotoList', ChosePhotoList.photos)
}
const GenerateParamsForUrl = (ValuesList) => {
    let ParamList = {}
    if (toolBoxSetUp) {
        toolBoxSetUp.map((item, key) => {
            ParamList[toolBoxSetUp[key].paramKey] = {
                value: ValuesList[item.name],
                key: `&${item.name}`
            }
        })
    }

    return ParamList
}
const GenerateQueryUrl = (Url, ParamList) => {
    let stringUrl = Url
    if (toolBoxSetUp) {
        toolBoxSetUp.map((item) => {
            let val = ParamList[item.paramKey].value,
                valKey = ParamList[item.paramKey].key;
            if (!val) return stringUrl
            stringUrl += `${valKey}=${val}`
        })
    }
    return stringUrl
}
const getList = async (take = 0, page = 1, filter = '') => {
    ToggleLoader(true, true)
    let Url = `/file-manager?page=${page}&take=${take}`
    const ValuesList = {
        filter
    }
    try {
        const res = await http.get(GenerateQueryUrl(Url, GenerateParamsForUrl(ValuesList)))

        return {
            data: res.data.data,
            meta: res.data.meta,
            status: res.status
        }
    } catch (error) {
        ToggleLoader(false, false)
        return errorMessage(ErrorHandler(error))
    }
}
const handleSearchValue = (value) => {
    state.search = value
    state.currentPage = 1
    ToggleLoader(true, true)
    getList(state.perPage, 1, value).then(payload => {
        let {data, meta, status} = payload
        if (status === 200 && data) {
            state.list = data
            state.itemCount = meta.itemCount
            ToggleLoader(false, false)
        }
    })
}
const handlePageChange = (pageNumber) => {
    ToggleLoader(true, true)
    state.currentPage = pageNumber
    getList(state.perPage, pageNumber, state.search).then(payload => {
        let {data, status} = payload
        if (status === 200 && data) {
            state.list = data
            ToggleLoader(false, false)
        }
    })
}
</script>
