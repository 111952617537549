<template>
    <a-modal
        :visible="PopUpVisibility"
        width="100%"
        wrap-class-name="full-modal ant-modal-file-manager"
        :destroyOnClose="true"
        :footer="null"
        :closable="false"
        :onCancel="handleClosePopUp"
    >
        <template #title>
            <span>Attachment Details</span>
            <button type="button" aria-label="Close" class="ant-modal-close" @click="handleClosePopUp">
                <span class="ant-modal-close-x">
                    <span role="img" aria-label="close" class="anticon anticon-close ant-modal-close-icon">
                        <svg focusable="false" class="" data-icon="close" width="1em" height="1em" fill="currentColor"
                             aria-hidden="true" viewBox="64 64 896 896">
                            <path
                                d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"/>
                        </svg>
                    </span>
                </span>
            </button>
        </template>

        <div class="file-box">
            <PictureComponent
                :url="PopUpData.image"
                :alt="PopUpData.keywords"
                :title="PopUpData.title"
            />

            <div class="info-box">
                <div class="details">
                    <div class="filename"><strong>Image Code:</strong> {{ PopUpData.image_code }}</div>
                    <div class="uploaded"><strong>Shoot Date:</strong>
                        {{ moment(PopUpData.shoot_date).format('DD-MM-YYYY') }}
                    </div>
                    <div class="dimensions"><strong>Dimensions:</strong> {{ PopUpData.width + 'x' + PopUpData.height }}
                    </div>
                </div>

                <a-form
                    ref="formRef"
                    :model="formState"
                    name="file_edit_form"
                    autocomplete="off"
                    :validate-messages="validateMessages"
                    @finish="Submit"
                >
                    <div class="field-row">
                        <a-form-item
                            label="Url"
                            name="url"
                        >
                            <a-input :disabled="true" v-model:value="formState.url" readonly/>
                        </a-form-item>
                    </div>
                    <div class="field-row">
                        <a-form-item
                            label="Photographer"
                            name="photographer_id"
                            :rules="[{ required: true}]"
                        >
                            <a-select
                                show-search
                                v-model:value="formState.photographer_id"
                                placeholder="Please select"
                                :options="PhotographersList"
                                :filter-option="filterDropDownOptions"
                            />
                        </a-form-item>
                    </div>
                    <div class="field-row">
                        <a-form-item
                            label="Description"
                            name="description"
                            :rules="[{ required: true}]"
                        >
                            <a-textarea v-model:value="formState.description"/>
                        </a-form-item>
                    </div>
                    <div class="field-row">
                        <a-form-item
                            label="Keywords"
                            name="keywords"
                            :rules="[{ required: true}]"
                        >
                            <a-input v-model:value="formState.keywords"/>
                        </a-form-item>
                    </div>

                    <div class="field-row">
                        <a-form-item
                            label="Status"
                            name="is_active"
                        >
                            <a-switch v-model:checked="formState.is_active"/>
                        </a-form-item>
                    </div>

                    <div class="field-row field-row-button">
                        <button-component
                            type="save"
                            text="save"
                            color="blue"
                            v-on:redirect="data => {state.closeFilePopUp = data}"
                        />
                        <button-component
                            type="saveAndClose"
                            text="save and close"
                            color="orange"
                            v-on:redirect="data => {state.closeFilePopUp = data}"
                        />
                    </div>
                </a-form>
            </div>
        </div>
    </a-modal>
</template>

<script setup>
/* eslint-disable */
import store from "@/store";
import {computed, reactive, watch} from "vue";
import {Delete, getDataById, Update} from "@/http/requests";
import {ToggleLoader} from "@/helpers";
import PictureComponent from "@/components/partials/PictureComponent.vue";
import moment from "moment";
import validateMessages from "@/helpers/validateMessages";
import {filterDropDownOptions} from "@/helpers/filterDropDownOptions";

defineProps({
    PhotographersList: Array
})

const CdnUrl = computed(() => {
    return store.state.CDN_URL
})

const PopUpVisibility = computed(() => {
    return store.state.FileManager.PopUpVisibility
})

const PopUpData = computed(() => {
    return store.state.FileManager.PopUpData
})

const FileID = computed(() => {
    return store.state.FileManager.FileID
})

const state = reactive({
    closeFilePopUp: false
})

const formState = reactive({
    url: PopUpData.value.image ? CdnUrl.value + PopUpData.value.image : null,
    title: PopUpData.value.title ? PopUpData.value.title : null,
    description: PopUpData.value.description ? PopUpData.value.description : null,
    keywords: PopUpData.value.keywords ? PopUpData.value.keywords : null,
    is_active: PopUpData.value.is_active ? PopUpData.value.is_active : false,
    photographer_id: PopUpData.value.photographer_id ? PopUpData.value.photographer_id : null,
})

const handleClosePopUp = () => {
    store.commit('FileManager/changePopUpVisibility', false)
    store.commit('FileManager/setFileId', '')
    store.commit('FileManager/setPopUpData', {})
}

watch(PopUpData, (data) => {
    if (data.id) {
        formState.url = CdnUrl.value + data.image
        formState.description = data.description
        formState.keywords = data.keywords
        formState.is_active = !!data.is_active
        formState.photographer_id = data.photographer_id
    }
})

const Submit = values => {
    values.is_active = values.is_active ? 1 : 0
    Update(`/file-manager/${FileID.value}`, values).then(res => {
        if (res.success) {
            store.commit('FileManager/setUpdatedFileData', {id: FileID.value, ...values})
            if (state.closeFilePopUp) return handleClosePopUp()
        }
    })

}

</script>
