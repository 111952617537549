<template>
    <div class="main-box">
        <a-form
            :model="formState"
            name="photographers_create"
            autocomplete="off"
            :validate-messages="validateMessages"
            @finish="Submit"
        >
            <div class="field-row half">
                <a-form-item
                    label="Photographer Avatar"
                    name="avatar"
                    :rules="[{ required: true}]"
                >
                    <ImageUpload v-on:file="handleFile"/>
                </a-form-item>
            </div>

            <div class="field-row half">
                <a-form-item
                    label="First Name"
                    name="first_name"
                    :rules="[{ required: true}]"
                >
                    <a-input v-model:value="formState.first_name" placeholder="First Name"/>
                </a-form-item>

                <a-form-item
                    label="Last Name"
                    name="last_name"
                    :rules="[{ required: true}]"
                >
                    <a-input v-model:value="formState.last_name" placeholder="Last Name"/>
                </a-form-item>
            </div>

            <div class="field-row half">
                <a-form-item
                    label="Position"
                    name="position"
                    :rules="[{ required: true}]"
                >
                    <a-input v-model:value="formState.position" placeholder="Position"/>
                </a-form-item>

                <a-form-item
                    label="Nick Name"
                    name="nick_name"
                    :rules="[{ required: true}]"
                >
                    <a-input v-model:value="formState.nick_name" placeholder="Nick Name"/>
                </a-form-item>

            </div>
            <div class="field-row half">
                <a-form-item
                    label="Type"
                    name="type"
                    :rules="[{ required: true}]"
                >
                    <a-select
                        show-search
                        v-model:value="formState.type"
                        placeholder="Please select"
                        :options="PhotographerTypes('arr')"
                        :filter-option="filterDropDownOptions"
                    />
                </a-form-item>
            </div>

            <div class="field-row">
                <a-form-item
                    label="Bio"
                    name="bio"
                >
                    <a-textarea v-model:value="formState.bio" placeholder="Bio" :rows="4"/>
                </a-form-item>
            </div>

            <div class="field-row">
                <a-form-item
                    label="Status"
                    name="is_active"
                >
                    <a-switch v-model:checked="formState.is_active"/>
                </a-form-item>
            </div>

            <a-form-item>
                <button-component type="submit" color="blue" text="Create"/>
            </a-form-item>
        </a-form>
    </div>
</template>

<script setup>
import validateMessages from "@/helpers/validateMessages";
import ImageUpload from "@/components/partials/ImageUpload";
import {reactive} from "vue";
import {Create} from "@/http/requests";
import {filterDropDownOptions} from "@/helpers/filterDropDownOptions";
import {PhotographerTypes} from "@/helpers/photographerType";

const formState = reactive({
    type: null,
    avatar: null,
    first_name: null,
    last_name: null,
    position: null,
    bio: '',
    nick_name: null,
    is_active: false
})

const Submit = (values) => {
    const postData = new FormData();
    postData.append('avatar', values.avatar);
    postData.append('first_name', values.first_name)
    postData.append('last_name', values.last_name);
    postData.append('nick_name', values.nick_name);
    postData.append('position', values.position);
    postData.append('type', values.type);
    postData.append('bio', values.bio);
    postData.append('is_active', values.is_active ? 1 : 0);

    Create('/photographers', postData, true)
}

const handleFile = (data) => {
    if (data) formState.avatar = data
}
</script>
