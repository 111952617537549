<template>
    <div class="main-box">
        <TableComponent
            :SetUp="toolBoxSetUp"
            :Columns="columns"
            ListUrl="/slider"
            :PerPage="50"
            CreateUrl="/slider/create"
        />
    </div>
</template>

<script setup>
import TableComponent from "@/components/partials/TableComponent";
const SliderTypes = {
    EDITOR_CHOICE: 'Editor Choice',
    HIGHLIGHTS: 'Highlights'
}
const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: 150,
        customRender: ({value}) => {
            return SliderTypes[value]
        }
    },
    {
        title: 'Status',
        dataIndex: 'is_active',
        key: 'is_active',
        width: 120,
        customRender: ({value}) => {
            if (value) {
                return (
                    <a-tag color="green">
                        ACTIVE
                    </a-tag>
                )
            }
            return <a-tag color="red">PASSIVE</a-tag>
        }
    },
    {
        title: 'Action',
        key: 'action',
        className: 'action-wrap',
        width: 100,
        customRender: ({value}) => {
            const {id} = value
            return <button-component text="edit" type="edit" color="orange" url={`/slider/${id}`}/>
        }
    },
]

const toolBoxSetUp = [
    {
        type: 'search',
        paramKey: '%s',
        name: 'filter',
        placeholder: 'Search by Text',
    }
]
</script>
