<template>
    <template v-for="(photo,photoIndex) in state.PhotoList" :key="photoIndex">
        <div class="photo-set-image-item">

            <div class="image-box">
                <a-image
                    :src="photo.thumbnail ? CdnUrl + photo.thumbnail : photo.base_64_url"
                    :preview="{
                      src: photo.image ? CdnUrl + photo.image : photo.base_64_url,
                    }"
                />
                <template v-if="photo.id">
                    <div class="field-row field-row-button">
                        <a-popconfirm placement="topLeft" v-model:visible="photo.DeleteBtnVisible" title="Are you sure">
                            <template #icon></template>
                            <template #cancelButton>
                                <button @click="state.PhotoList[photoIndex].DeleteBtnVisible = false"
                                        class="ant-btn ant-btn-orange">No
                                </button>
                            </template>
                            <template #okButton>
                                <button @click="handleReassignPhoto(photo.id,photoIndex)" class="ant-btn ant-btn-blue">
                                    Yes
                                </button>
                            </template>
                            <a-button type="primary" danger ghost>Remove</a-button>
                        </a-popconfirm>
                    </div>
                </template>
                <template v-else>
                    <div class="field-row field-row-button">
                        <a-button type="primary" danger ghost @click="removeFromUploadList(photo.image_code)">
                            Remove
                        </a-button>
                    </div>
                    <template v-if="photo.message">
                        <div class="ant-form-item-explain-error">
                            {{ photo.message }}
                        </div>
                    </template>
                </template>
            </div>
        </div>
    </template>

    <SliderPhotoAssign :SliderID="SliderID"  v-on:PhotoItem="handleAssignPhotoItem"/>
</template>

<script setup>
/* eslint-disable */
import {computed, reactive, watch} from "vue";
import http from "@/http";
import {ErrorHandler, errorMessage, successMessage} from "@/helpers";
import SliderPhotoAssign from "@/components/slider/SliderPhotoAssignModal.vue";
import store from "@/store";
import Sortable from "sortablejs";
let ImageListForDrag = []

const CdnUrl = computed(() => {
    return store.state.CDN_URL
})

const emit = defineEmits(['PhotoItem', 'PhotoListIsEmpty'])

const props = defineProps({
    List: Array,
    SliderID: Number,
})

const state = reactive({
    PhotoList: props.List?.length ? props.List : [],
})

watch(() => props.List, data => {
    state.PhotoList = data
    InitSortableDrag()

})
const handleReassignPhoto = async (id, itemIndex) => {
    state.PhotoList[itemIndex].loader = true
    try {
        const res = await http.post(`/slider/${props.SliderID}/remove/${id}`)
        const {message} = res.data
        state.PhotoList = state.PhotoList.filter(item => item.id !== id)
        if (!state.PhotoList.length) emit('PhotoListIsEmpty', true)
        return successMessage(message, 0.5)
    } catch (error) {
        state.PhotoList[itemIndex].loader = false
        state.PhotoList[itemIndex].DeleteBtnVisible = false
        return errorMessage(ErrorHandler(error))
    }
}
const removeFromUploadList = (imageCode) => {
    state.PhotoList = state.PhotoList.filter(item => item.image_code !== imageCode)
}
const handleAssignPhotoItem = data => {
    emit('PhotoItem', data)
}

const InitSortableDrag = () => {
    let box = document.getElementById('photo-set-image-list-slider')
    new Sortable.create(box, {
        animation: 100,
        dragClass: 'draggable',
        handle: '.photo-set-image-item',
        onUpdate: async function (evt) {
            let {oldIndex, newIndex} = evt
            const reorderedList = await reorderList(newIndex, oldIndex)
            sendReorderedList(reorderedList).then(payload => {
                let {message, status} = payload
                if (status === 200 && message) {
                    return successMessage(message)
                }
            })
        },
    });
}
const sendReorderedList = async (arr) => {
    try {
        const res = await http.post(`/slider/${props.SliderID}/reorder`, arr)
        return {
            message: res.data.message,
            status: res.status
        }
    } catch (error) {
        return errorMessage(ErrorHandler(error))
    }
}
const reorderList = (newIndex, oldIndex) => {
    return new Promise((resolve) => {
        let newList = ImageListForDrag.length ? ImageListForDrag : [...state.PhotoList]
        const element = newList[oldIndex];
        newList.splice(oldIndex, 1);
        newList.splice(newIndex, 0, element);
        ImageListForDrag = [...newList]
        resolve(
            ImageListForDrag.map((item, key) => {
                return {image_id: item.id, order_by: key}
            })
        )
    })
}
</script>
