/* eslint-disable no-template-curly-in-string */
import {createRouter, createWebHistory} from "vue-router";
import store from "@/store";

import LoginComponent from '@/components/LoginComponent.vue'
import FileManager from '@/components/partials/FileManager/MainComponent.vue'

import PhotographersList from "@/components/photographers/PhotographersList.vue";
import PhotographersCreate from "@/components/photographers/PhotographerCreate.vue";
import PhotographersEdit from "@/components/photographers/PhotographerEdit.vue";

import CollectionList from "@/components/collection/CollectionList.vue";
import CollectionCreate from "@/components/collection/CollectionCreate.vue";
import CollectionEdit from "@/components/collection/CollectionEdit.vue";
import ConfigComponent from "@/components/ConfigComponent.vue";
import SliderList from "@/components/slider/SliderList.vue";
import SliderCreate from "@/components/slider/SliderCreate.vue";
import SliderEdit from "@/components/slider/SliderEdit.vue";

const routes = [
    {
        path: "/login",
        name: "Login Component",
        component: LoginComponent,
        meta: {
            menuKey: 'login',
            public: true,
            onlyLoggedOut: true
        }
    },
    {
        path: "/photographers",
        name: "Photographers List",
        id: "photographers_list",
        component: PhotographersList,
        meta: {
            menuKey: 'photographers:list',
            public: false,
        }
    },
    {
        path: "/photographers/create",
        name: "Photographers Create",
        id: "photographers_create",
        component: PhotographersCreate,
        meta: {
            menuKey: 'photographers:list',
            public: false,
            parentUrl: '/photographers'
        }
    },
    {
        path: "/photographers/:PhotographerID",
        name: "Photographers Edit",
        id: "photographers_edit",
        component: PhotographersEdit,
        meta: {
            menuKey: 'photographers:list',
            public: false,
            parentUrl: '/photographers'
        }
    },
    {
        path: "/collection",
        name: "Collection List",
        id: "collection_list",
        component: CollectionList,
        meta: {
            menuKey: 'collection:list',
            public: false,
        }
    },
    {
        path: "/collection/create",
        name: "Collection Create",
        id: "collection_create",
        component: CollectionCreate,
        meta: {
            menuKey: 'collection:list',
            public: false,
            parentUrl: '/collection'
        }
    },
    {
        path: "/collection/:CollectionID",
        name: "Collection Edit",
        id: "collection_edit",
        component: CollectionEdit,
        meta: {
            menuKey: 'collection:list',
            public: false,
            parentUrl: '/collection'
        }
    },
    {
        path: "/slider",
        name: "Slider List",
        id: "slider_list",
        component: SliderList,
        meta: {
            menuKey: 'slider:list',
            public: false,
        }
    },
    {
        path: "/slider/create",
        name: "Slider Create",
        id: "slider_create",
        component: SliderCreate,
        meta: {
            menuKey: 'slider:list',
            public: false,
            parentUrl: '/slider'
        }
    },
    {
        path: "/slider/:SliderID",
        name: "Slider Edit",
        id: "slider_edit",
        component: SliderEdit,
        meta: {
            menuKey: 'slider:list',
            public: false,
            parentUrl: '/slider'
        }
    },
    {
        path: "/file-manager",
        name: "File Manager",
        id: "file_manger",
        component: FileManager,
        meta: {
            menuKey: 'file:manger',
            public: false,
        }
    },
    {
        path: "/config",
        name: "Config",
        id: "config",
        component: ConfigComponent,
        meta: {
            menuKey: 'config',
            public: false,
        }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/photographers',
    }
];

const router = new createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const authenticated = store?.state?.User?.token
    const onlyLoggedOut = to.matched.some(record => record.meta.onlyLoggedOut)
    const isPublic = to.matched.some(record => record.meta.public)
    if (to.path === '/login') {
        document.body.classList.add('page-login');
    }
    if (!isPublic && !authenticated) {
        return next({
            path: '/login',
        })
    }
    if (authenticated && onlyLoggedOut) {
        return next('/photographers')
    }
    next()
})

export default router;
