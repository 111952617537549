<template>
    <ToolBox
        v-on:onSearch="handleSearchValue"
        v-on:onDate="handleDateChange"
        v-on:forceUpdate="ForceUpdateList"
        :SetUp="SetUp"
        :create-url="CreateUrl"
    />
    <a-table
        :id="TableId"
        :scroll="{y: state.height - 264}"
        :dataSource="state.list"
        :pagination="false"
        :columns="Columns"
        :style="{height: state.height - 208 + 'px'}"
        :class="[ClassName, DragSort? 'draggable-table' : '']"
    />
    <a-pagination
        v-model:current="state.currentPage"
        :defaultPageSize="PerPage"
        simple
        :total="state.itemCount"
        @change="handlePageChange"
    />
</template>

<script setup>
/* eslint-disable */
import ToolBox from "@/components/partials/ToolBox";
import {ErrorHandler, errorMessage, successMessage, ToggleLoader, UseWindowSize} from "@/helpers";
import http from "@/http";
import dayjs from "dayjs";
import {onMounted, reactive} from "vue";
import Sortable from 'sortablejs';

const props = defineProps({
    TableId: String,
    Columns: {
        default: null,
        type: Array,
        require: true
    },
    ListUrl: String,
    ReorderUrl: String,
    CreateUrl: String,
    SetUp: Array,
    PerPage: {
        default: 50,
        type: Number
    },
    SyncUrl: {
        default: null,
        type: String
    },
    UploadUrl: {
        default: null,
        type: String
    },
    ClassName: {
        default: null,
        type: String
    },
    DragSort: Boolean
})

let TempListForDrag = []

const state = reactive({
    currentPage: 1,
    itemCount: null,
    filter: '',
    list: [],
    dateValue: dayjs(),
    width: UseWindowSize().width,
    height: UseWindowSize().height,
})

onMounted(() => {
    getList(props.PerPage).then(payload => {
        let {data, meta, status} = payload
        if (status === 200 && data) {
            state.list = data
            state.itemCount = meta.itemCount
            ToggleLoader(false, false)
            InitSortableDrag()
        }
    })
})

const InitSortableDrag = () => {
    if (!props.DragSort && !props.TableId) return false

    let table = document.getElementById(props.TableId)
    let tbody = table.getElementsByTagName('tbody')[0]
    new Sortable.create(tbody, {
        animation: 100,
        dragClass: 'draggable',
        handle: '.drag-icon',
        onUpdate: async function (evt) {
            let {oldIndex, newIndex} = evt
            const reorderedList = await reorderList(newIndex, oldIndex)
            sendReorderedList(reorderedList).then(payload => {
                let {message, status} = payload
                if (status === 200 && message) {
                    return successMessage(message)
                }
            })
        },
    });
}

const sendReorderedList = async (arr) => {
    try {
        const res = await http.post(props.ReorderUrl, arr)
        return {
            message: res.data.message,
            status: res.status
        }
    } catch (error) {
        return errorMessage(ErrorHandler(error))
    }
}

const reorderList = (newIndex, oldIndex) => {
    return new Promise((resolve, reject) => {
        newIndex -= 1
        oldIndex -= 1
        let newList = TempListForDrag.length ? TempListForDrag : [...state.list]
        const element = newList[oldIndex];
        newList.splice(oldIndex, 1);
        newList.splice(newIndex, 0, element);
        TempListForDrag = [...newList]
        resolve(
            TempListForDrag.map((item, key) => {
                return {id: item.id, order_by: key}
            })
        )
    })
}

const scrollToTableTop = () => {
    const tableBody = document.getElementsByClassName('ant-table-body')[0]
    tableBody.scroll(0, 0)
}

const ForceUpdateList = () => {
    getList(props.PerPage).then(payload => {
        let {data, meta, status} = payload
        if (status === 200 && data) {
            state.list = data
            state.itemCount = meta.itemCount
            state.currentPage = 1
            ToggleLoader(false, false)
        }
    })
}

const GenerateParamsForUrl = (ValuesList) => {
    let ParamList = {}
    if (props.SetUp) {
        props.SetUp.map((item, key) => {
            ParamList[props.SetUp[key].paramKey] = {
                value: ValuesList[item.name],
                key: `&${item.name}`
            }
        })
    }

    return ParamList
}

const GenerateQueryUrl = (Url, ParamList) => {
    let stringUrl = Url
    if (props.SetUp) {
        props.SetUp.map((item) => {
            let val = ParamList[item.paramKey].value,
                valKey = ParamList[item.paramKey].key;

            if (item.name === 'date') {
                if (!val) val = dayjs(state.dateValue).format('YYYY-MM-DD')
            }

            if (!val) return stringUrl

            stringUrl += `${valKey}=${val}`
        })
    }

    return stringUrl
}

const getList = async (take = 0, page = 1, filter = '', date = '') => {
    ToggleLoader(true, true)
    let Url = `${props.ListUrl}?page=${page}&take=${take}`
    const ValuesList = {
        filter,
        date
    }
    try {
        const res = await http.get(GenerateQueryUrl(Url, GenerateParamsForUrl(ValuesList)))
        scrollToTableTop()
        return {
            data: res.data.data,
            meta: res.data.meta,
            status: res.status
        }
    } catch (error) {
        ToggleLoader(false, false)
        return errorMessage(ErrorHandler(error))
    }
}

const handleDateChange = (value) => {
    const date = dayjs(value).format('YYYY-MM-DD')
    state.dateValue = value
    state.currentPage = 1
    ToggleLoader(true, true)
    getList(props.PerPage, 1, state.filter, date).then(payload => {
        let {data, meta, status} = payload
        if (status === 200 && data) {
            state.list = data
            state.itemCount = meta.itemCount
            ToggleLoader(false, false)
        }
    })
}

const handleSearchValue = (value) => {
    state.filter = value
    state.currentPage = 1
    ToggleLoader(true, true)
    getList(props.PerPage, 1, value, dayjs(state.dateValue).format('YYYY-MM-DD')).then(payload => {
        let {data, meta, status} = payload
        if (status === 200 && data) {
            state.list = data
            state.itemCount = meta.itemCount
            ToggleLoader(false, false)
        }
    })
}

const handlePageChange = (pageNumber) => {
    ToggleLoader(true, true)
    state.currentPage = pageNumber
    getList(props.PerPage, pageNumber, state.filter, dayjs(state.dateValue).format('YYYY-MM-DD')).then(payload => {
        let {data, status} = payload
        if (status === 200 && data) {
            state.list = data
            ToggleLoader(false, false)
        }
    })
}
</script>
