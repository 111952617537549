/* eslint-disable no-template-curly-in-string */
import {createStore} from 'vuex'
import {getLocalStorage, removeLocalStorageItem, setLocalStorage} from "@/helpers";

const store = createStore({
    state: {
        Loader: {
            show: false,
            transparent: false
        },
        User: getLocalStorage("user", true) ? getLocalStorage("user", true) : null,
        CDN_URL: null
    },
    modules: {
        FileManager: {
            namespaced: true,
            state() {
                return {
                    PopUpVisibility: false,
                    PopUpData: {},
                    FileID: '',
                    UpdatedFileData: {}
                }
            },
            getters: {
                PopUpVisibility(state) {
                    return state.PopUpVisibility
                },
                PopUpData(state) {
                    return state.PopUpData
                },
                FileID(state) {
                    return state.FileID()
                },
                UpdatedFileData(state) {
                    return state.UpdatedFileData()
                }
            },
            mutations: {
                setPopUpData(state, data) {
                    state.PopUpData = data
                },
                setFileId(state, data) {
                    state.FileID = data
                },
                changePopUpVisibility(state, data) {
                    state.PopUpVisibility = data
                },
                setUpdatedFileData(state, data) {
                    state.UpdatedFileData = data
                }
            }
        }
    },
    mutations: {
        SHOW_LOADER(state, data) {
            state.Loader = data
        },
        SET_CDN_URL(state, data) {
            state.CDN_URL = data
        },
        SET_USER() {
            window.location.href = '/photographers'
        },
        LOGOUT_USER(state) {
            state.User = {}
            window.location.href = '/login'
        }
    },
    actions: {
        LoaderAction({commit}, payload) {
            commit('SHOW_LOADER', payload)
        },
        SetCdnUrl({commit}, payload) {
            commit('SET_CDN_URL', payload)
        },
        SetUser({commit}, payload) {
            setLocalStorage('user', payload, true)
            commit('SET_USER', payload)
        },
        LogoutUser({commit}) {
            removeLocalStorageItem('user')
            commit('LOGOUT_USER')
        }
    }
})
export default store
