<template>
    <div class="main-box">
        <a-form
            :model="formState"
            name="photoset_create"
            autocomplete="off"
            :validate-messages="validateMessages"
            @finish="Submit"
        >
            <div class="field-row half">
                <a-form-item
                    label="Title"
                    name="title"
                    :rules="[{ required: true}]"
                >
                    <a-input v-model:value="formState.title" placeholder="Title"/>
                </a-form-item>

                <a-form-item
                    label="Date"
                    name="shoot_date"
                    :rules="[{ required: true}]"
                >
                    <a-date-picker
                        v-model:value="formState.shoot_date"
                        :allowClear="false"
                        :disabled-date="disabledDate"
                    />
                </a-form-item>
            </div>

            <div class="field-row half">
                <a-form-item
                    label="Meta Keyword"
                    name="keywords"
                >
                    <a-input v-model:value="formState.keywords" placeholder="Meta Keyword"/>
                </a-form-item>

                <a-form-item
                    label="Type"
                    name="type"
                    :rules="[{ required: true}]"
                >
                    <a-select
                        show-search
                        v-model:value="formState.type"
                        placeholder="Please select"
                        :options="CollectionTypes('arr')"
                        :filter-option="filterDropDownOptions"
                    />
                </a-form-item>
            </div>

            <div class="field-row half">
                <a-form-item
                    label="Meta Description"
                    name="description"
                    :rules="[{ required: true}]"
                >
                    <a-textarea show-count :maxlength="250" v-model:value="formState.description"
                                placeholder="Meta Description"/>
                </a-form-item>
            </div>
            <div class="field-row">
                <button-component type="submit" color="blue" text="Create"/>
            </div>
        </a-form>
    </div>
</template>

<script setup>
import {reactive} from "vue";
import validateMessages from "@/helpers/validateMessages";
import dayjs from "dayjs";
import {ErrorHandler, errorMessage, successMessage, ToggleLoader} from "@/helpers";
import http from "@/http";
import router from "@/router";
import {filterDropDownOptions} from "@/helpers/filterDropDownOptions";
import {CollectionTypes} from "@/helpers/collectionTypesList";

const formState = reactive({
    title: null,
    shoot_date: dayjs(),
    keywords: null,
    description: null,
    type: null
})
const disabledDate = current => {
    return current && current > dayjs().endOf('day');
};
const Submit = async (values) => {
    ToggleLoader(true, true)
    try {
        const res = await http.put('/collections', {...values, is_active: 0, is_future: 1})
        ToggleLoader(false, false)
        await router.replace(`/collection/${res.data.data.id}`)
        return successMessage('Collection created')
    } catch (error) {
        ToggleLoader(false, false)
        return errorMessage(ErrorHandler(error))
    }
}

</script>
