<template>
    <div class="image-box">
        <picture>
            <source loading="lazy" type="image/webp"
                    :srcset="CdnUrl + props.url.split('.').slice(0, -1).join('.') + '.webp'">
            <source loading="lazy" type="image/jpg"
                    :srcset="CdnUrl + props.url.split('.').slice(0, -1).join('.') + '.jpg'">
            <img :title="title"
                 loading="lazy"
                 :src="CdnUrl + props.url.split('.').slice(0, -1).join('.') + '.jpg'"
                 :alt="alt"
                 :width="width" :height="height"
            />
        </picture>
    </div>
</template>

<script setup>
/* eslint-disable */
import {computed} from "vue";
import store from "@/store";

const props = defineProps({
    url: String,
    width: Number,
    height: Number,
    title: String,
    alt: String,
})

const CdnUrl = computed(() => {
    return store.state.CDN_URL
})
</script>
