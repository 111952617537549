<template>
    <div class="main-box">
        <a-form
            :model="formState"
            name="photoset_create"
            autocomplete="off"
            :validate-messages="validateMessages"
            @finish="Submit"
        >
            <div class="field-row half">
                <a-form-item
                    label="Title"
                    name="title"
                    :rules="[{ required: true}]"
                >
                    <a-input v-model:value="formState.title" placeholder="Title"/>
                </a-form-item>

                <a-form-item
                    label="Type"
                    name="type"
                >
                    <a-input disabled="disabled" readonly v-model:value="formState.type"/>
                </a-form-item>
            </div>

            <div class="field-row half" v-if="state.SliderType === 'HIGHLIGHTS'">
                <a-form-item
                    label="Collection"
                    name="collection_id"
                    :rules="[{ required: true}]"
                >
                    <a-select
                        show-search
                        v-model:value="formState.collection_id"
                        placeholder="Please select"
                        :options="state.CollectionList"
                        :filter-option="filterDropDownOptions"
                    />
                </a-form-item>
            </div>

            <div class="field-row field-row-flex">
                <a-form-item
                    label="Status"
                    name="is_active"
                >
                    <a-switch v-model:checked="formState.is_active"/>
                </a-form-item>
            </div>

            <div class="field-row field-row-button">
                <button-component
                    type="save"
                    text="save"
                    color="blue"
                    v-on:redirect="data => {state.redirect = data}"
                />
                <button-component
                    type="saveAndClose"
                    text="save and close"
                    color="orange"
                    v-on:redirect="data => {state.redirect = data}"
                />
            </div>
            <div class="photo-set-image-list photo-set-image-list-slider" id="photo-set-image-list-slider" v-if="state.SliderType !== 'HIGHLIGHTS'">
                <template v-if="!formState.images.length">
                    <div class="empty-box">
                        There were no photos assign to this photo set
                    </div>
                </template>
                <SliderPhotoFormComponent
                    :List="formState.images"
                    v-on:PhotoListIsEmpty="handleIsEmpty"
                    :SliderID="SliderID"
                    v-on:PhotoItem="handlePhotoItem"
                />
            </div>
        </a-form>
    </div>
</template>

<script setup>
import {reactive, computed, onMounted} from "vue";
import {Update, getDataById, getDropDownOptions} from "@/http/requests";
import validateMessages from "@/helpers/validateMessages";
import {useRoute} from "vue-router";
import {ToggleLoader} from "@/helpers";
import ButtonComponent from "@/components/partials/Buttons/ButtonComponent.vue";
import {filterDropDownOptions} from "@/helpers/filterDropDownOptions";
import SliderPhotoFormComponent from "@/components/slider/SliderPhotoFormComponent.vue";

const router = useRoute()
const SliderID = computed(() => router.params.SliderID)

const SliderTypes = {
    EDITOR_CHOICE: 'Editor Choice',
    HIGHLIGHTS: 'Highlights'
}

const state = reactive({
    redirect: false,
    CollectionList: null,
    SliderType: null
})

const formState = reactive({
    title: null,
    is_active: false,
    type: null,
    collection_id: null,
    images: []
})

onMounted(() => {
    getDataById('/slider', SliderID.value, true).then(res => {
        const {data, status} = res
        if (status === 200 && data) {
            formState.title = data.title
            formState.is_active = !!data.is_active
            formState.collection_id = data.collection_id
            formState.images = data.images
            formState.type = SliderTypes[data.type]
            state.SliderType = data.type

            if (data.type === 'HIGHLIGHTS') {
                getDropDownOptions('/collections/dictionary/data/').then(res => {
                    const {data, status} = res
                    if (status === 200 && data) {
                        ToggleLoader(false, false)
                        state.CollectionList = data
                    }
                })
            } else {
                ToggleLoader(false, false)
            }
        }
    })
})
const handlePhotoItem = (data) => {
    formState.images.push(data)
}
const handleIsEmpty = (data) => {
    if (data) formState.images = []
}
const Submit = (values) => {
    delete values.type
    values.is_active = values.is_active ? 1 : 0
    Update(`/slider/${SliderID.value}`, values, state.redirect)
}
</script>
