/* eslint-disable no-template-curly-in-string */
import {ErrorHandler, errorMessage, successMessage, ToggleLoader} from "@/helpers";
import http from "@/http/index";
import router from "@/router";
import store from "@/store";

async function redirectToParentUrl() {
    return router.replace(router.currentRoute?.value?.meta?.parentUrl)
}

export async function getDataById(url, ID, redirect = false) {
    ToggleLoader(true, true)
    try {
        const res = await http.get(`${url}/${ID}`)
        return {
            data: res.data.data,
            status: res.status
        }
    } catch (error) {
        ToggleLoader(false, false)
        if (redirect) await redirectToParentUrl()
        errorMessage(ErrorHandler(error)).then()
        return {
            success: false,
        }
    }
}

export async function Create(url, values, multipart = false, redirect = true) {
    ToggleLoader(true, true)
    let headers = {}
    if (multipart) {
        headers = {
            'content-type': 'multipart/form-data',
        }
    }
    try {
        const res = await http.put(url, values, headers)
        const {message} = res.data
        ToggleLoader(false, false)
        if (redirect) await redirectToParentUrl()
        return successMessage(message)
    } catch (error) {
        ToggleLoader(false, false)
        return errorMessage(ErrorHandler(error))
    }
}

export async function Update(url, values, redirect = false, multipart = false) {
    ToggleLoader(true, true)
    let headers = {}
    if (multipart) {
        headers = {
            'content-type': 'multipart/form-data',
        }
    }
    try {
        const res = await http.patch(url, values, headers)
        const {message} = res.data
        ToggleLoader(false, false)
        if (redirect) await redirectToParentUrl()
        return successMessage(message, 0.5)
    } catch (error) {
        ToggleLoader(false, false)
        return errorMessage(ErrorHandler(error))
    }
}

export async function Delete(url, redirect = false) {
    ToggleLoader(true, true)
    try {
        const res = await http.delete(url)
        const {message} = res.data
        if (redirect) await redirectToParentUrl()
        successMessage(message).then()
        return {
            success: true
        }
    } catch (error) {
        ToggleLoader(false, false)
        return errorMessage(ErrorHandler(error))
    }
}

export async function Login(url, values) {
    ToggleLoader(true, true)
    try {
        let res = await http.post(url, values);
        let {data} = res;
        return store.dispatch('SetUser', data.data)
    } catch (error) {
        ToggleLoader(false, false)
        return {
            message: ErrorHandler(error)
        }
    }
}

export async function Logout(url) {
    ToggleLoader(true, true)
    try {
        let res = await http.post(url);

        if (res) {
            return store.dispatch('LogoutUser')
        }
    } catch (error) {
        ToggleLoader(false, false)
        return errorMessage(ErrorHandler(error), 4)
    }
}

export async function getDropDownOptions(url = '') {
    try {
        const res = await http.get(url)

        return {
            data: res.data.data,
            status: res.status
        }
    } catch (error) {
        return errorMessage(ErrorHandler(error))
    }
}

