<template>
    <a-modal
        :visible="state.visible"
        width="100%"
        wrap-class-name="full-modal ant-modal-file-manager chooser"
        :destroyOnClose="true"
        :footer="null"
        :closable="false"
        :onCancel="handleClosePopUp"
    >
        <FileManager
            ManagerType="select"
            :SelectedPhotos="state.PhotoList"
            v-on:SelectedPhotoList="handleSelectedPhotoList"
        />
    </a-modal>
    <div class="photo-set-image-chose-list">
        <template v-for="(photo,photoIndex) in state.PhotoList" :key="photoIndex">
            <div class="photo-set-image-chose-item">
                <LoaderComponent transparent="transparent" v-if="photo.loader"/>
                <div class="image-box">
                    <img :src="CdnUrl + photo.thumbnail" alt="alt"/>
                </div>
                <div class="file-info">
                    <span class="file-code">Code: {{ photo.image_code }}</span>
                </div>
                <template v-if="photo.message">
                    <div class="ant-form-item-explain-error">
                        {{ photo.message }}
                    </div>
                </template>
                <div class="field-row field-row-button">
                    <a-button type="primary" danger ghost @click="removeFromUploadList(photo.id)">
                        Remove
                    </a-button>
                </div>
            </div>
        </template>
    </div>

    <div class="field-row field-row-button">
        <a-button type="orange" @click="state.visible = true">Choose</a-button>
        <a-button v-if="state.PhotoList.length" type="blue" @click="PrepareForAssign">Assign</a-button>
    </div>
</template>

<script setup>
import FileManager from "@/components/partials/FileManager/MainComponent.vue"
import {computed, reactive} from "vue";
import LoaderComponent from "@/components/partials/LoaderComponent.vue";
import http from "@/http";
import {ErrorHandler, errorMessage} from "@/helpers";
import store from "@/store";

const props = defineProps({
    SliderID: Number
})

const CdnUrl = computed(() => {
    return store.state.CDN_URL
})
const emit = defineEmits(['PhotoItem'])
const state = reactive({
    visible: false,
    PhotoList: []
})

const handleClosePopUp = () => {
    state.visible = false
}

const handleSelectedPhotoList = data => {
    state.visible = false
    state.PhotoList = data
}

const removeFromUploadList = (imageId) => {
    state.PhotoList = state.PhotoList.filter(item => item.id !== imageId)
}

const PrepareForAssign = () => {
    Promise.all(
        state.PhotoList.map(async (item, key) => {
            let fileItem = {...item}
            state.PhotoList[key].loader = true

            const {data, status} = await DoUploadFile(fileItem.id)
            if (status === 200 && data) {
                data.success = true
                state.PhotoList = state.PhotoList.filter(item => item.image_code !== data.image_code)
                emit('PhotoItem', data)
            }
        })
    )
}
const DoUploadFile = async (FileId) => {
    try {
        const res = await http.post(`/slider/${props.SliderID}/assign/${FileId}`)
        return {
            data: res.data.data,
            status: res.status
        }
    } catch (error) {
        const itemIndex = state.PhotoList.findIndex(item => item.id === FileId)
        state.PhotoList[itemIndex].loader = false
        state.PhotoList[itemIndex].message = error.response?.data?.message
        return errorMessage(ErrorHandler(error))
    }
}
</script>
