<template>
    <div class="main-box">
        <TableComponent
            :SetUp="toolBoxSetUp"
            :Columns="columns"
            ListUrl="/collections"
            :PerPage="50"
            CreateUrl="/collection/create"
        />
    </div>
</template>

<script setup>
import TableComponent from "@/components/partials/TableComponent";
import moment from "moment";
import {CollectionTypes} from "@/helpers/collectionTypesList";

const columns = [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: 120,
        customRender: ({value}) => {
            return CollectionTypes('obj')[value]
        }
    },
    {
        title: 'Date',
        dataIndex: 'shoot_date',
        key: 'shoot_date',
        width: 120,
        customRender: ({value}) => {
            return moment(value).format('DD/MM/YYYY')
        }
    },
    {
        title: 'Status',
        dataIndex: 'is_active',
        key: 'is_active',
        width: 120,
        customRender: ({value}) => {
            if (value) {
                return (
                    <a-tag color="green">
                        ACTIVE
                    </a-tag>
                )
            }
            return <a-tag color="red">PASSIVE</a-tag>
        }
    },
    {
        title: 'Action',
        key: 'action',
        className: 'action-wrap',
        width: 100,
        customRender: ({value}) => {
            const {id} = value
            return <button-component text="edit" type="edit" color="orange" url={`/collection/${id}`}/>
        }
    },
]

const toolBoxSetUp = [
    {
        type: 'search',
        paramKey: '%s',
        name: 'filter',
        placeholder: 'Search by Text',
    }
]
</script>
