export function PhotographerTypes(type = 'arr') {
    if (type === 'obj') {
        return {
            Founder: 'Founder',
            Staff: 'Staff',
            Cooperator: 'Cooperator',
            Other: 'Other',
        }
    }
    return [
        {
            value: 'Founder',
            label: 'Founder',
        },
        {
            value: 'Staff',
            label: 'Staff',
        },
        {
            value: 'Cooperator',
            label: 'Cooperator',
        },
        {
            value: 'Other',
            label: 'Other'
        }
    ]
}
