<template>
    <template v-for="(photo,photoIndex) in state.PhotoList" :key="photoIndex">
        <a-form
            class="photo-set-image-item"
            :model="state.PhotoList[photoIndex]"
            :name="'photoset_photo_edit_' + photoIndex"
            autocomplete="off"
            :validate-messages="validateMessages"
            @finish="EditPhoto(photoIndex)"
        >
            <LoaderComponent transparent="transparent" v-if="photo.loader"/>

            <div class="image-box">
                <a-image
                    :src="photo.thumbnail ? CdnUrl + photo.thumbnail : photo.base_64_url"
                    :preview="{
                      src: photo.image ? CdnUrl + photo.image : photo.base_64_url,
                    }"
                />
                <template v-if="photo.id">
                    <div class="field-row">
                        <a-form-item label="Main Image">
                            <a-switch :checked="state.MainImageId === photo.id"
                                      @click="handleMainImageChange(photo.id,photo.is_active)"/>
                        </a-form-item>
                    </div>
                    <div class="field-row field-row-button">
                        <a-popconfirm placement="topLeft" v-model:visible="photo.DeleteBtnVisible" title="Are you sure">
                            <template #icon></template>
                            <template #cancelButton>
                                <button @click="state.PhotoList[photoIndex].DeleteBtnVisible = false"
                                        class="ant-btn ant-btn-orange">No
                                </button>
                            </template>
                            <template #okButton>
                                <button @click="handleReassignPhoto(photo.id,photoIndex)" class="ant-btn ant-btn-blue">
                                    Yes
                                </button>
                            </template>
                            <a-button type="primary" danger ghost>Remove</a-button>
                        </a-popconfirm>
                        <template v-if="photo.is_active">
                            <a-button html-type="submit" type="orange">Edit</a-button>
                        </template>
                    </div>
                </template>
                <template v-else>
                    <div class="field-row field-row-button">
                        <a-button type="primary" danger ghost @click="removeFromUploadList(photo.image_code)">
                            Remove
                        </a-button>
                    </div>
                    <template v-if="photo.message">
                        <div class="ant-form-item-explain-error">
                            {{ photo.message }}
                        </div>
                    </template>
                </template>
            </div>

            <div class="image-info">
                <div class="field-row">
                    <a-form-item
                        label="Image Code"
                        name="image_code"
                    >
                        <a-input :disabled="true"
                                 v-model:value="state.PhotoList[photoIndex].image_code"
                                 placeholder="Title"/>
                    </a-form-item>
                </div>

                <div class="field-row">
                    <a-form-item
                        label="Photographer"
                        name="photographer_id"
                        :rules="[{ required: photo.is_active}]"
                    >
                        <a-select
                            show-search
                            v-model:value="state.PhotoList[photoIndex].photographer_id"
                            placeholder="Please select"
                            :options="PhotographersList"
                            :filter-option="filterDropDownOptions"
                            :disabled="photo.id && !photo.is_active"
                        />
                    </a-form-item>
                </div>

                <div class="field-row">
                    <a-form-item
                        label="Keywords"
                        name="keywords"
                        :rules="[{ required: photo.is_active}]"
                    >
                        <a-input :disabled="photo.id && !photo.is_active"
                                 v-model:value="state.PhotoList[photoIndex].keywords"
                                 placeholder="Keywords"/>
                    </a-form-item>
                </div>

                <div class="field-row">
                    <a-form-item
                        label="Description"
                        name="description"
                        :rules="[{ required: photo.is_active}]"
                    >
                        <a-textarea :disabled="photo.id && !photo.is_active" placeholder="Description" show-count
                                    :maxlength="250"
                                    v-model:value="state.PhotoList[photoIndex].description"/>
                    </a-form-item>
                </div>

            </div>
        </a-form>
    </template>

    <template v-if="type=== 'upload' && CollectionType === 'Simple'">
        <div class="field-row field-row-button">
            <ImageUploadButton v-on:file="handleFile"/>
            <a-button v-if="state.PhotoList.length" type="blue" @click="UploadFiles">Upload</a-button>
        </div>
    </template>

    <template v-if="CollectionType === 'Collection'">
        <PhotoAssign :CollectionID="CollectionID" v-on:PhotoItem="handleAssignPhotoItem"/>
    </template>
</template>

<script setup>
import {computed, reactive} from "vue";
import validateMessages from "@/helpers/validateMessages";
import {filterDropDownOptions} from "@/helpers/filterDropDownOptions";
import http from "@/http";
import {ErrorHandler, errorMessage, successMessage, ToggleLoader, warningMessage} from "@/helpers";
import LoaderComponent from "@/components/partials/LoaderComponent.vue";
import ImageUploadButton from "@/components/partials/Buttons/ImageUploadButton.vue";
import PhotoAssign from "@/components/collection/PhotoAssignModal.vue";
import store from "@/store";

const CdnUrl = computed(() => {
    return store.state.CDN_URL
})

const emit = defineEmits(['PhotoItem', 'PhotoListIsEmpty', 'MainImageId'])

const props = defineProps({
    List: Array,
    PhotographersList: Array,
    CollectionID: String,
    CollectionType: String,
    type: String,
    MainImageId: Number
})

const state = reactive({
    PhotoList: props.List?.length ? props.List : [],
    MainImageId: props.MainImageId ? props.MainImageId : Number,
})

const handleMainImageChange = async (id, status) => {
    if (id === state.MainImageId) return warningMessage('Operation not allowed!')
    if (!status) return warningMessage('You can\'t sat as main disabled image')
    ToggleLoader(true, true)
    try {
        const res = await http.patch(`/collections/${props.CollectionID}/main/${id}`)
        const {message} = res.data
        ToggleLoader(false, false)
        state.MainImageId = id
        emit('MainImageId', id)
        return successMessage(message, 0.5)
    } catch (error) {
        ToggleLoader(false, false)
        return errorMessage(ErrorHandler(error))
    }
}

const handleReassignPhoto = async (id, itemIndex) => {
    state.PhotoList[itemIndex].loader = true
    try {
        const res = await http.post(`/collections/${props.CollectionID}/remove/${id}`)
        const {message} = res.data
        state.PhotoList = state.PhotoList.filter(item => item.id !== id)
        if (!state.PhotoList.length) emit('PhotoListIsEmpty', true)
        return successMessage(message, 0.5)
    } catch (error) {
        state.PhotoList[itemIndex].loader = false
        state.PhotoList[itemIndex].DeleteBtnVisible = false
        return errorMessage(ErrorHandler(error))
    }
}
const EditPhoto = async (photoIndex) => {
    let photoItem = state.PhotoList[photoIndex]
    photoItem.loader = true
    const postData = {
        description: photoItem.description,
        keywords: photoItem.keywords,
        photographer_id: photoItem.photographer_id,
        is_active: photoItem.is_active,
    }
    try {
        const res = await http.patch(`/file-manager/${photoItem.id}`, postData)
        const {message} = res.data
        photoItem.loader = false
        return successMessage(message, 0.5)
    } catch (error) {
        photoItem.loader = false
        return errorMessage(ErrorHandler(error))
    }
}
const handleFile = (data) => {
    if (data) {
        const Photographer = props.PhotographersList.filter(item => item.nick_name === data.nick_name)
        state.PhotoList.push(
            {
                ...data,
                is_main: false,
                loader: false,
                photographer_id: Photographer[0]?.value ? Photographer[0].value : null
            }
        )
    }
}
const UploadFiles = async () => {
    let itemCount = state.PhotoList.length
    state.PhotoList.map((item, key) => {
        const {
            description,
            keywords,
            photographer_id
        } = item
        if (!description || !keywords || !photographer_id) {
            itemCount--
            state.PhotoList[key].is_valid = false
            state.PhotoList[key].message = 'Make sure that all info inserted'
        } else {
            delete state.PhotoList[key].is_valid
            delete state.PhotoList[key].message
        }
    })

    if (itemCount === state.PhotoList.length) return PrepareForUpload()
}
const PrepareForUpload = () => {
    Promise.all(
        state.PhotoList.map(async (item, key) => {
            let fileItem = {...item}
            state.PhotoList[key].loader = true
            delete fileItem.base_64_url
            delete fileItem.is_valid
            delete fileItem.loader
            const postData = new FormData();
            postData.append('image', fileItem.file)
            postData.append('description', fileItem.description)
            postData.append('image_code', fileItem.image_code)
            postData.append('keywords', fileItem.keywords)
            postData.append('photographer_id', fileItem.photographer_id)
            postData.append('type', props.CollectionType)

            const {data, status} = await DoUploadFile(postData, fileItem.image_code)
            if (status === 200 && data) {
                data.success = true
                state.PhotoList = state.PhotoList.filter(item => item.image_code !== data.image_code)
                emit('PhotoItem', data)
            }
        })
    )
}
const DoUploadFile = async (item, imageCode) => {
    try {
        const res = await http.post(`/collections/${props.CollectionID}/assign`, item, {
            headers: {
                'content-type': 'multipart/form-data',
            }
        })
        return {
            data: res.data.data,
            status: res.status
        }
    } catch (error) {
        const itemIndex = state.PhotoList.findIndex(item => item.image_code === imageCode)
        state.PhotoList[itemIndex].loader = false
        state.PhotoList[itemIndex].message = error.response?.data?.message
        return errorMessage(ErrorHandler(error))
    }
}
const removeFromUploadList = (imageCode) => {
    state.PhotoList = state.PhotoList.filter(item => item.image_code !== imageCode)
}
const handleAssignPhotoItem = data => {
    emit('PhotoItem', data)
}
</script>
