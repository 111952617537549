/* eslint-disable no-template-curly-in-string */
export function CollectionTypes(type = 'arr') {
    if (type === 'obj') {
        return {
            Simple: 'Simple',
            Collection: 'Collection'
        }
    }
    return [
        {
            value: 'Simple',
            label: 'Simple',
        },
        {
            value: 'Collection',
            label: 'Collection'
        }
    ]
}
