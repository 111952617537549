<template>
    <div class="main-box">
        <a-form
            :model="formState"
            name="config"
            autocomplete="off"
            :validate-messages="validateMessages"
            @finish="Submit"
        >
            <div class="field-row">
                <a-form-item
                    class="ant-row-editor-json"
                    label="Config"
                    name="config"
                    :rules="[{ required: true}]"
                >
                    <JsonEditorVue :mainMenuBar="false" v-model="formState.config" mode="text"/>
                </a-form-item>
            </div>
            <a-form-item>
                <button-component type="submit" color="blue" text="Save"/>
            </a-form-item>
        </a-form>
    </div>
</template>

<script setup>
/* eslint-disable */
import JsonEditorVue from 'json-editor-vue'
import {onMounted, reactive} from "vue";
import validateMessages from "@/helpers/validateMessages";
import {ErrorHandler, errorMessage, ToggleLoader} from "@/helpers";
import http from "@/http";
import {Create} from "@/http/requests";

const formState = reactive({
    config: ''
})
onMounted(async () => {
    ToggleLoader(true, true)
    const ConfigData = await getConfigData()
    if (ConfigData?.status === 200 && ConfigData?.data) {
        formState.config = ConfigData.data
        ToggleLoader(false, false)
    }
})
const getConfigData = async () => {
    try {
        const res = await http.get('/configuration')
        return {
            data: res.data.data,
            status: res.status
        }
    } catch (error) {
        ToggleLoader(false, false)
        return errorMessage(ErrorHandler(error))
    }
}

const Submit = (values) => {
    typeof values.config === 'object' ? values.config = JSON.stringify(values.config) : values.config
    Create('/configuration', values)
}
</script>
