import {createApp} from 'vue'
import App from './App.vue'
import router from "@/router";
import store from "@/store";
import {
    Avatar,
    Form,
    Input,
    InputNumber,
    Button,
    Menu,
    Table,
    Pagination,
    Tag,
    Typography,
    Switch,
    Select,
    Space,
    Drawer,
    DatePicker,
    Checkbox,
    Radio,
    Empty,
    Image,
    Popconfirm,
    Popover,
    Modal
} from 'ant-design-vue';
import ButtonComponent from '@/components/partials/Buttons/ButtonComponent'
import {DragOutlined} from '@ant-design/icons-vue';

const app = createApp(App)
app.component('ButtonComponent', ButtonComponent)
app.component('DragOutlined', DragOutlined)
if (process.env.VUE_APP_NODE_ENV === 'production') {
    app.config.devtools = false
    app.config.productionTip = false
}

app.use(router)
app.use(Drawer)
app.use(Modal)
app.use(Form)
app.use(Input)
app.use(Button)
app.use(Menu)
app.use(Checkbox)
app.use(Table)
app.use(Pagination)
app.use(Tag)
app.use(Typography)
app.use(Switch)
app.use(Space)
app.use(Select)
app.use(DatePicker)
app.use(Radio)
app.use(InputNumber)
app.use(Empty)
app.use(Image)
app.use(Popconfirm)
app.use(Avatar)
app.use(Popover)
app.use(store)
app.mount('#photo-lure-dashboard')
