<template>
    <input class="hidden" type="file" id="icon" @change="handleFileChange"/>
    <label class="image-label"
           htmlFor="icon">
        <template v-if="state.ImageUrl || props.image">
            <a-image :preview="false" :src="state.ImageUrl || props.image"/>
        </template>
        <template v-else>
            <div class="wrap">
                <div>Select</div>
            </div>
        </template>
    </label>
</template>
<script setup>
import {errorMessage} from "@/helpers";
import {reactive} from "vue";

const emit = defineEmits(['file'])
const props = defineProps({
    file: Function,
    image: String
})
const state = reactive({
    fileMaxSize: 15,
    ImageUrl: ''
})

const handleFileChange = (data) => {
    const file = data.target.files[0]
    if (!file) return
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < state.fileMaxSize;

    if (!isJpgOrPng || !isLt2M) {
        if (!isJpgOrPng) {
            errorMessage('You can only upload JPG/PNG file!')
        }
        if (!isLt2M) {
            errorMessage(`Image must smaller than ${state.fileMaxSize}MB!`)
        }
        return false
    }

    getBase64(file, imageUrl => {
            state.ImageUrl = imageUrl
            emit('file', file)
        }
    );
}

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}
</script>
