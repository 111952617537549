<template>
    <div class="login-container">
        <div class="login-form">
            <div class="logo">
                <Logo :with-label="true"/>
            </div>
            <a-form
                :model="formState"
                name="login_form"
                autocomplete="off"
                :validate-messages="validateMessages"
                @finish="Submit"
            >
                <a-form-item
                    label="User Name"
                    name="username"
                    :rules="[{required: true}]"
                >
                    <a-input placeholder="User Name" autocomplete="off" v-model:value="formState.username"/>
                </a-form-item>

                <a-form-item
                    label="Password"
                    name="password"
                    class="password-filed-box"
                    :rules="[{ required: true}]"
                >
                    <a-input placeholder="Password" autocomplete="off" type="password"
                             v-model:value="formState.password"/>
                </a-form-item>

                <a-form-item>
                    <a-button type="blue" html-type="submit">Log In</a-button>
                </a-form-item>

                <div class="response-box" v-if="state.errorMessage">{{ state.errorMessage }}</div>
            </a-form>
        </div>
    </div>
</template>

<script setup>
import {reactive} from 'vue';
import validateMessages from "@/helpers/validateMessages";
import {Login} from "@/http/requests";
import Logo from "@/assets/icons/PhotoLurLogo.vue"

const formState = reactive({
    username: '',
    password: '',
})

const state = reactive({
    errorMessage: '',
})

const Submit = (values) => {
    Login('/public/auth/login', values).then(res => {
        if (res?.message) {
            state.errorMessage = res.message
        }
    })
}
</script>
