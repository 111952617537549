/* eslint-disable no-template-curly-in-string */
import {ref, onMounted, onUnmounted} from 'vue'

export function UseWindowSize() {
    const width = ref(document.documentElement.clientWidth)
    const height = ref(document.documentElement.clientHeight)

    function updateSize(e) {
        width.value = e.target.innerWidth
        height.value = e.target.innerHeight
    }
    onMounted(() => {
        window.addEventListener('resize', updateSize)
    })

    onUnmounted(() => {
        window.removeEventListener('resize', updateSize)
    })

    return {width, height}
}
