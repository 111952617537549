<template>
    <div class="main-box">
        <TableComponent
            :SetUp="toolBoxSetUp"
            :Columns="columns"
            ListUrl="/photographers"
            :PerPage="50"
            CreateUrl="/photographers/create"
            TableId="photographers-table"
            :DragSort="true"
            ReorderUrl="/photographers/reorder"
        />
    </div>
</template>

<script setup>
import TableComponent from "@/components/partials/TableComponent";
import {computed} from "vue";
import store from "@/store";

const CdnUrl = computed(() => {
    return store.state.CDN_URL
})
const columns = [
    {
        title: 'Pic',
        dataIndex: 'avatar',
        key: 'avatar',
        width: 100,
        customRender: ({value}) => {
            return (
                <div className="avatar-wrap">
                    <div className="drag-icon">
                        <drag-outlined/>
                    </div>
                    <a-avatar src={CdnUrl.value + value}/>
                </div>
            )
        }
    },
    {
        title: 'Full Name',
        dataIndex: 'full_name',
        key: 'full_name',
        customRender: ({record}) => {
            let {first_name, last_name} = record
            return <a-typography-text>{first_name} {last_name}</a-typography-text>
        }
    },
    {
        title: 'Position',
        dataIndex: 'position',
        key: 'position',
        width: 240
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: 100
    },
    {
        title: 'Nick Name',
        dataIndex: 'nick_name',
        key: 'nick_name',
        width: 300
    },
    {
        title: 'Status',
        dataIndex: 'is_active',
        key: 'is_active',
        width: 120,
        customRender: ({value}) => {
            if (value) {
                return (
                    <a-tag color="green">
                        ACTIVE
                    </a-tag>
                )
            }
            return <a-tag color="red">PASSIVE</a-tag>
        }
    },
    {
        title: 'Action',
        key: 'action',
        className: 'action-wrap',
        width: 100,
        customRender: ({value}) => {
            const {id} = value
            return <button-component text="edit" type="edit" color="orange" url={`/photographers/${id}`}/>
        }
    },
]

const toolBoxSetUp = [
    {
        type: 'search',
        paramKey: '%s',
        name: 'filter',
        placeholder: 'Search by Text',
    }
]
</script>
