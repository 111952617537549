<template>
    <input class="hidden" type="file" multiple accept="image/png,image/jpg,image/jpeg" id="files"
           @change="handleFileChange"/>
    <label class="ant-btn ant-btn-orange" for="files">
        Add Photo
    </label>
</template>

<script setup>
import {reactive} from "vue";
import EXIF from "exif-js";

const emit = defineEmits(['file'])

const state = reactive({
    fileMaxSize: 15
})

const handleFileChange = async (data) => {
    const files = data.target.files
    if (!files.length) return

    Array.from(files).map(async (file) => {
        let metaData = {}
        EXIF.getData(file, function () {
            metaData = {
                ...validateFile(file),
                keywords: file.iptcdata.keywords ? file.iptcdata.keywords.join(', ') : '',
                description: file.iptcdata.caption ? file.iptcdata.caption : '',
                nick_name: file.iptcdata.byline,
                file: file,
                image_code: file.name.split('.')[0]
            }
            getBase64(file, imageUrl => {
                    metaData.base_64_url = imageUrl
                    emit('file', metaData)
                }
            );
        })
    })


}

const validateFile = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < state.fileMaxSize;
    let message = ''
    if (!isJpgOrPng || !isLt2M) {
        if (!isJpgOrPng) {
            message = 'You can only upload JPG/PNG file!'
        }
        if (!isLt2M) {
            message = `Image should be smaller than ${state.fileMaxSize}MB!`
        }
        return {is_valid: false, message}
    }
    return {is_valid: true}
}

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}


</script>
