export function setLocalStorage(name, data, jsonStringify = false) {
    localStorage.setItem(name, jsonStringify === true ? JSON.stringify(data) : data);
}

export function getLocalStorage(name, jsonParse = false) {
    return (jsonParse) ? JSON.parse(localStorage.getItem(name)) : localStorage.getItem(name)
}

export function removeLocalStorageItem(name) {
    localStorage.removeItem(name);
}

export function clearLocalStorage() {
    localStorage.clear();
}

