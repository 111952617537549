<template>
    <div class="main-box">
        <a-form
            :model="formState"
            name="photographers_create"
            autocomplete="off"
            :validate-messages="validateMessages"
            @finish="Submit"
        >
            <div class="field-row half">
                <a-form-item
                    label="Photographer Avatar"
                    name="avatar"
                    :rules="[{ required: true}]"
                >
                    <ImageUpload :image="formState.avatar" v-on:file="handleFile"/>
                </a-form-item>
            </div>

            <div class="field-row half">
                <a-form-item
                    label="First Name"
                    name="first_name"
                    :rules="[{ required: true}]"
                >
                    <a-input v-model:value="formState.first_name" placeholder="First Name"/>
                </a-form-item>

                <a-form-item
                    label="Last Name"
                    name="last_name"
                    :rules="[{ required: true}]"
                >
                    <a-input v-model:value="formState.last_name" placeholder="Last Name"/>
                </a-form-item>

            </div>

            <div class="field-row half">
                <a-form-item
                    label="Position"
                    name="position"
                    :rules="[{ required: true}]"
                >
                    <a-input v-model:value="formState.position" placeholder="Position"/>
                </a-form-item>

                <a-form-item
                    label="Nick Name"
                    name="nick_name"
                    :rules="[{ required: true}]"
                >
                    <a-input v-model:value="formState.nick_name" placeholder="Nick Name"/>
                </a-form-item>

            </div>

            <div class="field-row half">
                <a-form-item
                    label="Type"
                    name="type"
                    :rules="[{ required: true}]"
                >
                    <a-select
                        show-search
                        v-model:value="formState.type"
                        placeholder="Please select"
                        :options="PhotographerTypes('arr')"
                        :filter-option="filterDropDownOptions"
                    />
                </a-form-item>
            </div>

            <div class="field-row">
                <a-form-item
                    label="Bio"
                    name="bio"
                >
                    <a-textarea v-model:value="formState.bio" placeholder="Bio" :rows="4"/>
                </a-form-item>
            </div>

            <div class="field-row">
                <a-form-item
                    label="Status"
                    name="is_active"
                >
                    <a-switch v-model:checked="formState.is_active"/>
                </a-form-item>
            </div>

            <div class="field-row field-row-button">
                <button-component
                    type="save"
                    text="save"
                    color="blue"
                    v-on:redirect="data => {state.redirect = data}"
                />
                <button-component
                    type="saveAndClose"
                    text="save and close"
                    color="orange"
                    v-on:redirect="data => {state.redirect = data}"
                />
            </div>
        </a-form>
    </div>
</template>

<script setup>
import ImageUpload from "@/components/partials/ImageUpload";
import {reactive, computed, onMounted} from "vue";
import {Update, getDataById} from "@/http/requests";
import validateMessages from "@/helpers/validateMessages";
import {useRoute} from "vue-router";
import {ToggleLoader} from "@/helpers";
import {PhotographerTypes} from "@/helpers/photographerType";
import {filterDropDownOptions} from "@/helpers/filterDropDownOptions";
import store from "@/store";

const router = useRoute()
const PhotographerID = computed(() => router.params.PhotographerID)

const state = reactive({
    redirect: false
})

const formState = reactive({
    type: null,
    avatar: null,
    first_name: null,
    last_name: null,
    position: null,
    bio: '',
    nick_name: null,
    is_active: false
})
const CdnUrl = computed(() => {
    return store.state.CDN_URL
})
onMounted(() => {
    getDataById('/photographers', PhotographerID.value, true).then(res => {
        const {data, status} = res
        if (status === 200 && data) {
            ToggleLoader(false, false)
            formState.type = PhotographerTypes('obj')[data.type]
            formState.avatar = CdnUrl.value + data.avatar
            formState.first_name = data.first_name
            formState.last_name = data.last_name
            formState.position = data.position
            formState.bio = data.bio
            formState.nick_name = data.nick_name
            formState.is_active = !!data.is_active
        }
    })
})

const Submit = (values) => {
    const postData = new FormData();
    if (typeof values.avatar !== 'string') {
        postData.append('avatar', values.avatar);
    }
    postData.append('first_name', values.first_name)
    postData.append('last_name', values.last_name);
    postData.append('nick_name', values.nick_name);
    postData.append('position', values.position);
    postData.append('bio', values.bio);
    postData.append('is_active', values.is_active ? 1 : 0);
    postData.append('type', values.type);

    Update(`/photographers/${PhotographerID.value}`, postData, state.redirect, true)
}

const handleFile = (data) => {
    if (data) formState.avatar = data
}
</script>
