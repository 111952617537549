<template>
    <div class="main-box">
        <a-form
            :model="formState"
            name="photoset_create"
            autocomplete="off"
            :validate-messages="validateMessages"
            @finish="Submit"
        >
            <div class="field-row half">
                <a-form-item
                    label="Title"
                    name="title"
                    :rules="[{ required: true}]"
                >
                    <a-input v-model:value="formState.title" placeholder="Title"/>
                </a-form-item>

                <a-form-item
                    label="Date"
                    name="shoot_date"
                >
                    <a-date-picker
                        readonly
                        disabled="disabled"
                        v-model:value="formState.shoot_date"
                        :allowClear="false"
                        :disabled-date="disabledDate"
                    />
                </a-form-item>
            </div>

            <div class="field-row half">
                <a-form-item
                    label="Meta Keyword"
                    name="keywords"
                >
                    <a-input v-model:value="formState.keywords" placeholder="Meta Keyword"/>
                </a-form-item>

                <a-form-item
                    label="Type"
                    name="type"
                >
                    <a-input disabled="disabled" readonly v-model:value="formState.type"/>
                </a-form-item>

            </div>

            <div class="field-row half">
                <a-form-item
                    label="Meta Description"
                    name="description"
                    :rules="[{ required: true}]"
                >
                    <a-textarea show-count :maxlength="220" v-model:value="formState.description"
                                placeholder="Meta Description"/>
                </a-form-item>

            </div>

            <div class="field-row field-row-flex">
                <a-form-item
                    label="Status"
                    name="is_active"
                >
                    <a-switch v-model:checked="formState.is_active"/>
                </a-form-item>

                <a-form-item
                    label="Show In Home"
                    name="is_future"
                >
                    <a-switch v-model:checked="formState.is_future"/>
                </a-form-item>
            </div>

            <div class="field-row field-row-button">
                <button-component
                    type="save"
                    text="save"
                    color="blue"
                    v-on:redirect="data => {state.redirect = data}"
                />
                <button-component
                    type="saveAndClose"
                    text="save and close"
                    color="orange"
                    v-on:redirect="data => {state.redirect = data}"
                />

            </div>

            <div class="photo-set-image-list">
                <template v-if="!formState.photos.length">
                    <div class="empty-box">
                        There were no photos assign to this photo set
                    </div>
                </template>

                <PhotoFormComponent
                    v-else
                    :List="formState.photos"
                    :PhotographersList="state.PhotographersList"
                    :CollectionID="CollectionID"
                    v-on:PhotoListIsEmpty="handleIsEmpty"
                    v-on:MainImageId="data => state.MainImageId = data"
                    :MainImageId="state.MainImageId"
                />

                <PhotoFormComponent
                    type="upload"
                    :CollectionType="state.CollectionType"
                    :PhotographersList="state.PhotographersList"
                    v-on:PhotoItem="handlePhotoItem"
                    :CollectionID="CollectionID"
                />
            </div>
        </a-form>
    </div>
</template>

<script setup>
import {reactive, computed, onMounted} from "vue";
import {Update, getDataById, getDropDownOptions} from "@/http/requests";
import validateMessages from "@/helpers/validateMessages";
import {useRoute} from "vue-router";
import {ToggleLoader, warningMessage} from "@/helpers";
import dayjs from "dayjs";
import ButtonComponent from "@/components/partials/Buttons/ButtonComponent.vue";
import PhotoFormComponent from "@/components/collection/PhotoFormComponent.vue";
import {CollectionTypes} from "@/helpers/collectionTypesList";

const router = useRoute()
const CollectionID = computed(() => router.params.CollectionID)

const state = reactive({
    redirect: false,
    PhotographersList: [],
    CollectionType: null,
    MainImageId: null
})

const formState = reactive({
    title: null,
    date: dayjs(),
    keywords: null,
    description: null,
    is_active: false,
    is_future: false,
    type: null,
    photos: []
})

onMounted(() => {
    getDataById('/collections', CollectionID.value, true).then(res => {
        const {data, status} = res
        if (status === 200 && data) {
            ToggleLoader(false, false)
            formState.title = data.title
            formState.shoot_date = dayjs(data.shoot_date)
            formState.keywords = data.keywords
            formState.description = data.description
            formState.is_active = !!data.is_active
            formState.is_future = !!data.is_future
            formState.photos = data.images
            formState.type = CollectionTypes('obj')[data.type]
            state.CollectionType = data.type
            state.MainImageId = data.main_image_id
        }
    })


    getDropDownOptions('/photographers/dictionary/data').then(res => {
        const {data, status} = res
        if (status === 200 && data) {
            state.PhotographersList = data
        }
    })
})
const handlePhotoItem = (data) => {
    formState.photos.push(data)
}
const handleIsEmpty = (data) => {
    if (data) formState.photos = []
}
const disabledDate = current => {
    return current && current > dayjs().endOf('day');
};
const Submit = (values) => {
    delete values.shoot_date
    delete values.type
    if (values.is_active && !state.MainImageId) return warningMessage('You can\'t set enable Collection without main image!')
    values.is_active = values.is_active ? 1 : 0
    values.is_future = values.is_future ? 1 : 0
    Update(`/collections/${CollectionID.value}`, values, state.redirect)
}
</script>
